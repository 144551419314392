import './App.css';
import FeedBack from "./pages/feedback/Feedback";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Frame from "./pages/frame/Frame";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import ClockInvite from './pages/clock-invite/ClockInvite';
import Invite from './pages/invite/Invite';
import AutoRenewAgreement from "./pages/AutoRenewAgreement";
import PrimeMembershipContract from "./pages/PrimeMembershipContract";
import Home from "./pages/home/Home";
import Medicine from "./pages/medicine/Medicine";
import MedicineDetail from "./pages/medicine/detail/MedicineDetail";
import ServiceAgreement from "./pages/ServiceAgreement";
import InviteAgreement from "./pages/invite/InviteAgreement";

function App() {
  return <>
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Frame />}>
          <Route index element={<Home/>} />
          <Route path="medicines" element={<Medicine/>} />
          <Route path="medicine/detail/:name" element={<MedicineDetail />} />
        </Route>
        <Route path="/coin-invite" exact element={<ClockInvite />} />
        <Route path="/invite" exact element={<Invite />} />
        <Route path="/invite-privacy" exact element={<InviteAgreement />} />
        <Route path="/auto-renew-agreement" exact element={<AutoRenewAgreement />} />
        <Route path="/prime-membership-contract" exact element={<PrimeMembershipContract />} />
        <Route path="/service-agreement" exact element={<ServiceAgreement />} />
        <Route path="/privacy" exact element={<PrivacyPolicy />} />
        <Route path="/feedback" exact element={<FeedBack />} />
      </Routes>
    </BrowserRouter>
  </>;
}

export default App;
