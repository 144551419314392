import React, {useEffect} from "react";

export default function InviteAgreement(props) {

  useEffect(() => {
    document.title = "中济经络穴位付费会员服务协议"
  }, [])

  return <div style={{height: "100vh", padding: "0 15px", "overflow": "auto", wordBreak: "break-all"}}>
    <h2 style={{textAlign: "center", marginTop: "20px"}}>中济经络穴位会员推广合作协议</h2>
    <div style={{fontSize: "15px", marginTop: "10px"}}>
      发布日期：2022年 08 月 29 日
    </div>
    <div
      style={{fontWeight: "bold", marginTop: "16px"}}>【特别提示】
    </div>
    <div className="text-item">
      欢迎您加入中济经络穴位推广计划，参与活动前，请您仔细阅读《中济经络穴位推广合作协议》（以下简称“本协议”）的全部内容。如果您对本协议内容有任何异议或不同意本协议下的任何条款，请勿同意本协议或使用本协议下的服务。否则，将视为您同意并接受本协议的全部内容，本协议将在您和中济经络穴位之间产生法律效力，成为对双方均具有约束力的法律文件。
    </div>
    <div className="text-item">
      本协议中相关名词解释：
    </div>
    <div className="text-item">
      本协议所称“中济经络穴位推广计划”（以下简称“推广计划”）是指中济经络穴位用户申请成为中济经络穴位推广计划成员，通过参与特定的推广活动对中济经络穴位包括但不限于会员产品进行推广，并根据本协议及相关的活动规则获得对应佣金的促销活动计划。</div>
    <div className="text-item">
      本协议所称“您”、“中济经络穴位推广计划成员”或“推广计划成员”均指参加中济经络穴位推广计划的中济经络穴位用户。
    </div>
    <div className="text-item">
      本协议所称“推广产品”是指：中济会员、或其他中济经络穴位不时推出的增值服务（具体以相关活动页面宣传为准），以下合称“中济经络穴位推广服务”。
    </div>
    <div className="text-item">
      本协议由您和重庆知源健康管理有限公司（以下称“中济经络穴位”）共同签署，中济经络穴位及您均已认真阅读本协议、《中济经络穴位服务协议》、《中济经络穴位隐私政策》及相关服务条款、章程和规则，对本协议及相关服务条款、章程和规则均已知晓、理解并接受，同意将其作为确定双方权利义务的依据。如果您对本协议的条款有疑问，您可通过中济经络穴位App客户端联系在线客服进行咨询。
    </div>
    <div className="text-item">
      中济经络穴位有权根据自身的业务需要不定期更新本协议的内容，如本协议发生变更，中济经络穴位将在相关页面以公告的方式或消息、短信等方式提前予以公布。若您在协议生效后继续参加推广计划的，表示您接受变更后的协议，也将按照变更后协议的约定使用享受推广计划的相关权益。如您拒绝接受变更后的协议内容，您须退出推广计划。
    </div>
    <div className="text-item">
      如您为无民事行为能力人或限制民事行为能力人，请告知您的监护人，并在您的监护人的指导下阅读本协议和使用本服务。若您非自然人，则您确认，您有权订立本协议且履行本协议不受您所属、所居住或开展业务的国家或地区法律法规的限制。
    </div>
    <div style={{marginTop: "16px", fontWeight: "bold"}}>
      第一条 协议目的
    </div>
    <div className="text-item">
      为了更好地向推广计划成员提供服务同时规范推广计划成员的行为，明确双方享有的权利和应履行的义务，特拟定本协议。
    </div>
    <div style={{marginTop: "16px", fontWeight: "bold"}}>
      第二条 协议订立、生效和适用范围
    </div>
    <div className="text-item">
      2.1 申请参加中济经络穴位推广计划的中济经络穴位用户应当点击确认同意本协议，本协议自用户点击确认同意之日起即对双方生效。未确认本协议但实际参与中济经络穴位推广计划的，均视为成员与中济经络穴位已经就本协议达成一致并同意接受本协议的全部约定内容。
    </div>
    <div className="text-item">
      2.2 本协议包括本协议正文以及所有中济经络穴位已经发布或者将来可能发布的各类规则、规范、通知等。规则、规范及通知等作为本协议的组成部分，与本协议具有同等法律效力。
    </div>
    <div style={{marginTop: "16px", fontWeight: "bold"}}>
      第三条 加入中济经络穴位推广计划
    </div>
    <div className="text-item">
      3.1 加入成为中济经络穴位推广计划成员需同时具备以下条件：
    </div>
    <div className="text-item">
      （1）注册成为中济经络穴位用户，拥有中济经络穴位账号；
    </div>
    <div className="text-item">
      （2）其他中济经络穴位不时要求的资格条件。
    </div>
    <div className="text-item">
      3.2 符合上述条件的用户可以向中济经络穴位发起申请，经中济经络穴位审核通过后，中济经络穴位将为用户开通相应的推广计划账户权限。若开通推广计划权限的中济经络穴位账号因违规、资格条件不具备或其他原因被暂停使用或终止使用时，则相应的推广计划成员资格暂停使用或终止使用，用户已取得的佣金不予提现。
    </div>
    <div className="text-item">
      3.3 加入推广计划成为成员无需任何费用，中济经络穴位不会向您收取任何费用，您也不得以任何理由向您推荐的其他推广计划成员收取任何形式的加盟费或其他费用。 中济经络穴位如发现您利用推广计划向第三方收取类似加盟费或其他费用的，中济经络穴位有权取消您的成员资格，封停您的账户，扣除您的全部佣金，并要求您退还已经收取的全部费用，由此导致中济经络穴位或其他用户损失的，您应该负责全额赔偿。
    </div>
    <div className="text-item">
      3.4 为保证您将您参加推广计划获得的佣金提现至您的支付账户，您应当向中济经络穴位运营人员提供您的实名信息、银行卡账号、支付宝账号、邮寄地址等信息用于奖品兑换。您理解并同意，中济经络穴位有权查询您的个人身份信息，以便监测和防范交易风险。
    </div>
    <div style={{marginTop: "16px", fontWeight: "bold"}}>
      第四条 推广计划内容
    </div>
    <div className="text-item">
      4.1 您根据本协议注册成为中济经络穴位推广计划成员后，您可通过分享链接分享您的邀请码或邀请链接给好友，好友使用您的邀请码或打开邀请链接后可获得专属中济经络穴位会员优惠券，当好友支付完成后，系统会记录你们的返佣关系，若具有返佣关系的用户在指定时间内购买中济经络穴位会员，并且未在规定的时间内退款的，您将获得相应的佣金。详细的推广计划内容，以相关活动页面公布的为准。
    </div>
    <div className="text-item">
      4.2 您了解并同意，推广计划内容以相关活动页面展示的规则为准，且中济经络穴位随时可能根据业务的发展或系统功能的调整等增加或修改推广计划的内容，并就调整的内容通过系统公告、系统更新提醒或其他合理方式告知您。如您对更新内容有异议的，可以选择不再继续参加推广计划，双方可解除合作，如您继续参加推广计划的，视为您同意并接受上述更新内容。
    </div>
    <div style={{marginTop: "16px", fontWeight: "bold"}}>
      第五条 佣金和结算
    </div>
    <div className="text-item">
      5.1 若您参加推广计划并达到推广计划规定的条件，且不存在违反本协议其他条款的情况时，您有权获得相应的佣金。
    </div>
    <div className="text-item">
      5.2 您可在中济经络穴位开放的通知的结算时间内（具体以相关活动页面为准）发起佣金结算，并通过中济经络穴位客户端的活动页面提交提现申请，提现申请经过中济经络穴位审核通过后，将通过支付宝、微信、银行卡等方式发放至您的账户中。
    </div>
    <div className="text-item">
      5.3 中济经络穴位会员卡一经售出不予退换，若经您推广售出的会员卡有效期内出现退款的情况，退费会员对应部分的佣金将不予结算，已经结算的部分您需要全额退还。
    </div>
    <div className="text-item">
      5.4 您理解并同意，您支取的佣金，中济经络穴位有权为您代扣代缴各类税费，因此可能造成您实际到账的金额小于系统显示的佣金金额，您不持任何异议。
    </div>
    <div className="text-item">
      5.5 在参加推广计划过程中，一切硬件、软件、服务和其他费用成本，均由成员自行承担。
    </div>
    <div style={{marginTop: "16px", fontWeight: "bold"}}>
      第六条 成员行为限制
    </div>
    <div className="text-item">
      6.1 您在注册和提交信息时，应保证提供的身份信息和填写的相关信息的真实性、准确性和合法性。同时，您在参加推广计划过程中，若相应信息发生变更的，应及时进行更新，若因您提供虚假不实信息或未及时更新信息，导致任何损失的，由您自行承担，同时中济经络穴位有权关停其服务账户、单方面停止合作。
    </div>
    <div className="text-item">
      6.2 您应妥善保管其中济经络穴位账号及密码，避免遗失或被盗，同时成员不得将账号出租、转让或授予他人使用。
    </div>
    <div className="text-item">
      6.4 您应遵守中济经络穴位发布的各项规则，若不同意某项规则，应及时停止使用服务。若您在推广计划发布新规则后，继续参加推广计划，则视为您同意该规则，愿意接受该规则约束。
    </div>
    <div className="text-item">
      6.5 您应按照法律法规的规定及推广计划的相关要求参与推广计划，若您在参与推广计划过程中有违法违规行为或中济经络穴位根据自身判断，认为您的行为存在违法违规侵权行为的，中济经络穴位有权按照本协议关停您的账户、单方面停止合作。同时，若因您的行为给中济经络穴位造成任何损失、被第三方索赔、被用户投诉或被行政机关处罚的，成员应赔偿中济经络穴位因此遭受的所有损失和费用，包括但不限于经济损失、赔偿金、罚金、合理的律师费、给第三方的赔偿金和其他合理支出等，并负责消除对中济经络穴位造成的不利影响。
    </div>
    <div className="text-item">
      6.6 推广计划成员在通过推广计划进行推广时，应遵循下列约定：
    </div>
    <div className="text-item">
      （1） 不违反国家相关法律法规的规定、不违反自身与第三方签署的协议/约定的义务和推广计划的相关要求，诚实守信，遵守社会公共秩序和道德，不损害他人合法利益。成员若违反上述约定造成任何损害后果的，均由成员独立承担全部法律责任，包括赔偿因此给第三方或中济经络穴位造成的损失。
    </div>
    <div className="text-item">
      （2） 按照推广计划要求进行推广，不得采取任何欺骗或虚假的手段进行推广，以获取不真实的推广效果数据。
    </div>
    <div className="text-item">
      （3）不得采取欺骗、诱导、误导、强制或其他有损中济经络穴位声誉的行为，或者利用与被推广对无关的内容向目标用户推广，包括但不限于强制消费、暴力发送短信和推广信息、使用恶意软件捆绑安装、强制安装消费、利用侵权或色情暴力等无关内容进行推广、利用返点等给予现金的方式诱导用户消费等违反用户自由选择权的行为。
    </div>
    <div className="text-item">
      （4）不得采取不正当手段损害其他推广者的正常推广行为。
    </div>
    <div className="text-item">
      （5）不得采取技术手段或其他黑客措施攻击、更改推广计划服务器及数据，或采取其他措施干扰推广计划服务器正常工作。
    </div>
    <div className="text-item">
      （6）不得存在作弊刷单行为，关于作弊刷单行为中济经络穴位在法律法规允许的范围内拥有解释权；
    </div>
    <div className="text-item">
      （7）未经中济经络穴位书面允许，不得以中济经络穴位名义进行推广；
    </div>
    <div className="text-item">
      （8）成员应维护与提高中济经络穴位和中济经络穴位产品的形象，不得作出、散播任何有损或与事实不符的关于中济经络穴位或中济经络穴位产品的言论或行为；
    </div>
    <div className="text-item">
      （9）不得从事其他任何损害中济经络穴位利益或其他第三方合法权益的行为。
    </div>
    <div className="text-item">
      6.7 未经中济经络穴位同意，成员不得使用中济经络穴位的相关标识、商标，不得声称为中济经络穴位或其相关服务的代理商、或有其他控制关系等，不得采取使用户误以为其为中济经络穴位工作人员或与推广计划具有某种关系（控股、合伙、投资、服务、代理等）的行为，若因成员违反上述规定，造成中济经络穴位或第三方利益受损的，推广计划有权终止其成员资格，并有权就该行为造成的损失向成员追偿，包括但不限于经济损失、赔偿金、罚金、合理的律师费用、给第三方的赔偿金和其他合理支出等，并负责消除对中济经络穴位造成的不利影响。
    </div>
    <div className="text-item">
      6.8 成员应对因本协议知悉或获取的中济经络穴位的所有信息、材料（包括但不限于价格、价格体系、市场推广计划、会员人数、优惠政策等）等严格保密，不得未经中济经络穴位授权自行使用或授权任何第三人使用、传播等。
    </div>
    <div style={{marginTop: "16px", fontWeight: "bold"}}>
      第七条 违规处理
    </div>
    <div className="text-item">
      7.1  若您违反本协议或推广计划发布的各项规则的相关规定的，您同意中济经络穴位有权对您采取下列一项或多项处理措施：
    </div>
    <div className="text-item">
      （1）暂停您参加推广计划的资格；
    </div>
    <div className="text-item">
      （2）不予结算涉嫌违规行为涉及的部分或全部佣金；
    </div>
    <div className="text-item">
      （3）不予结算或直接扣除推广计划服务账户下已产生及当月将要产生的部分或全部佣金；
    </div>
    <div className="text-item">
      （4）取消您参加推广计划的资格，并扣除服务账户下全部未结算费用；
    </div>
    <div className="text-item">
      （5）关停您的中济经络穴位账号；
    </div>
    <div className="text-item">
      （6）要求您进行对中济经络穴位的损失进行赔偿。
    </div>
    <div className="text-item">
      就您的违规给中济经络穴位及第三方造成的损失，通过上述措施仍不能弥补的，中济经络穴位保留向您追偿的权利，追偿的范围包括但不限于中济经络穴位为此支出的所有费用及开支。
    </div>
    <div className="text-item">
      7.2 若您对中济经络穴位处理措施有异议的，可在通过中济经络穴位给出的申诉途径提供相反的证据材料进行申诉。申诉不影响已给出处理措施的执行。逾期未申诉的，视为您同意相关处理结果。
    </div>
    <div style={{marginTop: "16px", fontWeight: "bold"}}>
      第八条 责任范围和限制
    </div>
    <div className="text-item">
      任何情况下，中济经络穴位在本协议项下承担的全部赔偿责任不应超过中济经络穴位根据应协议应当向推广计划成员支付的佣金总额的100%。
    </div>
    <div style={{marginTop: "16px", fontWeight: "bold"}}>
      第九条 推广计划的终止
    </div>
    <div className="text-item">
      9.1 中济经络穴位有权随时中止或终止推广计划，包括暂时关闭或者永久关闭成员在推广计划的服务账户，且无须向推广计划成员或者第三方承担责任。终止自推广计划向成员发出通知之日起终止。
    </div>
    <div className="text-item">
      9.2  在下列情况下，中济经络穴位有权终止中济经络穴位会员推广计划：
    </div>
    <div className="text-item">
      （1）在成员违反本协议的相关规定时，中济经络穴位有权终止向该成员提供推广计划服务;
    </div>
    <div className="text-item">
      （2）中济经络穴位发现成员的行为、数据或者信息含有虚假内容;
    </div>
    <div className="text-item">
      （3）本协议终止或者修订中，成员明示不愿意接受新的服务协议;
    </div>
    <div className="text-item">
      （4）其他中济经络穴位认为需要终止服务的情况。
    </div>
    <div className="text-item">
      9.3 无论本协议因何种原因终止，在协议终止前的行为导致的任何赔偿和责任，成员必须完全且独立地承担责任。
    </div>
    <div style={{marginTop: "16px", fontWeight: "bold"}}>
      第十条 协议的变更
    </div>
    <div className="text-item">
      10.1 中济经络穴位有权修改本协议和规则的相关内容，并在相关页面网站进行公示或通过短信、站内信等方式通知成员，而无需征得成员事先同意。
    </div>
    <div className="text-item">
      10.2 修改后的条款于公示时、通知时或公示通知指定的时间即时生效。
    </div>
    <div className="text-item">
      10.3 在中济经络穴位修改本协议和相关规则后，若您不同意相关变更的，应停止参加推广计划，否则如您继续参加推广计划的，视作您已接受了修改后的条款。
    </div>
    <div style={{marginTop: "16px", fontWeight: "bold"}}>
      第十一条 通知和送达
    </div>
    <div className="text-item">
      11.1 中济经络穴位向成员发送的所有通告，将通过页面公告、站内信或短信的方式传送。本协议条款的修改、服务变更、或其它重要事件的通告都会以此形式进行。
    </div>
    <div className="text-item">
      11.2 您同意中济经络穴位或中济经络穴位的关联公司或合作伙伴可以向您发送短信信息。
    </div>
    <div style={{marginTop: "16px", fontWeight: "bold"}}>
      第十二条 其他约定
    </div>
    <div className="text-item">
      12.1本活动的内容、产品种类、文案、图标、产品定价等可能会根据实际运营情况随时调整，以活动页面展示为准，不另行通知。
    </div>
    <div className="text-item">
      12.2本平台可能同时存在多个不同的营销活动，不同活动产生的奖励不共用，只能在对应的活动中进行查询、使用。
    </div>
    <div className="text-item">
      12.3 本协议取代各方就有关本协议所载任何事项于先前以口头及书面达成的共识。
    </div>
    <div className="text-item">
      12.4 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国大陆地区法律。
    </div>
    <div className="text-item">
      12.5 如发生本协议条款与上述法律相抵触时，则这些条款将完全按法律规定重新解释，而其它合法条款则依旧保持对用户产生法律效力和影响。
    </div>
    <div className="text-item">
      12.6 本协议的规定是可分割的，如本协议任何规定被裁定为无效或不可执行，该规定可被删除而其余条款应予以执行。
    </div>
    <div className="text-item">
      12.7 如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向重庆知源健康管理有限公司所在地提起诉讼。
    </div>
    <div className="text-item">
      12.8 如您对本协议有任何问题或建议的，可以通过中济经络穴位客户端联系客服反馈，中济经络穴位收到后会及时处理。
    </div>
    <div className="text-item" style={{textAlign: "right", marginTop: "16px"}}>
      重庆知源健康管理有限公司
    </div>
  </div>
}
