import React from "react";
import MedicineCard from "../../components/medicine_card"

export default () => {
    const medicines = require(`../../jsons/medicines/medicines.json`);

    return (
        <div>
            <div className="medicine-list" style={{display: "flex"}}>
                <MedicineCard title={medicines.zhongyao[0]}/>
                <MedicineCard title={medicines.zhongyao[1]}/>
                <MedicineCard title={medicines.zhongyao[2]}/>
                <MedicineCard title={medicines.zhongyao[3]}/>
                <MedicineCard title={medicines.zhongyao[4]}/>
                <MedicineCard title={medicines.zhongyao[5]}/>
            </div>
            <div className="medicine-list" style={{display: "flex"}}>
                <MedicineCard title={medicines.zhongyao[6]}/>
                <MedicineCard title={medicines.zhongyao[7]}/>
                <MedicineCard title={medicines.zhongyao[8]}/>
                <MedicineCard title={medicines.zhongyao[9]}/>
                <MedicineCard title={medicines.zhongyao[10]}/>
                <MedicineCard title={medicines.zhongyao[11]}/>
            </div>
        </div>
    )
}