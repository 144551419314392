import React from "react";
import "./Medicine.css";
import IntroduceTitle from "../../components/introduce_title/IntroduceTitle";
import ChineseMedicineList from "../../components/chinese_medicine_list/ChineseMedicineList";
import MedicineCard from "../../components/medicine_card";

export default () => {
    const medicines = require(`../../jsons/medicines/medicines.json`);

    return (
        <div className="medicines">
            <div className="list zhongyao">
                <IntroduceTitle title="常用中药"/>
                <ChineseMedicineList/>
            </div>
            <div className="list chengyao">
                <IntroduceTitle title="中成药"/>
                <div>
                    <MedicineCard title={medicines.chengyao[0]} />
                    <MedicineCard title={medicines.chengyao[1]} />
                    <MedicineCard title={medicines.chengyao[2]} />
                    <MedicineCard title={medicines.chengyao[3]} />
                </div>
                <div>
                    <MedicineCard title={medicines.chengyao[4]} />
                    <MedicineCard title={medicines.chengyao[5]} />
                    <MedicineCard title={medicines.chengyao[6]} />
                    <MedicineCard title={medicines.chengyao[7]} />
                </div>
            </div>
            <div className="list xiyao">
                <IntroduceTitle title="西药"/>
                <div>
                    <MedicineCard title={medicines.xiyao[0]} />
                    <MedicineCard title={medicines.xiyao[1]} />
                    <MedicineCard title={medicines.xiyao[2]} />
                    <MedicineCard title={medicines.xiyao[3]} />
                </div>
                <div>
                    <MedicineCard title={medicines.xiyao[4]} />
                    <MedicineCard title={medicines.xiyao[5]} />
                    <MedicineCard title={medicines.xiyao[6]} />
                    <MedicineCard title={medicines.xiyao[7]} />
                </div>
            </div>
        </div>
    )
}