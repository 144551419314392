const config = {
    baseURL: process.env.BASE_API || 'http://192.168.1.111:4000/api',
    marketUrl: "market://details?id=com.mc.acupoint_3d",
    appStoreUrl: "itms-apps://itunes.apple.com/app/id1554030035",
}

const regExps = {
    name: /^(?:[\u4e00-\u9fa5]+)(?:[ꔷ•·][\u4e00-\u9fa5]+)*$|^[a-zA-Z0-9]+\s?[.ꔷ•·\-()a-zA-Z]*[a-zA-Z]+$/,
    cell_phone: /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/,
    tell_phone: /^(0\\d{2}-\\d{8}(-\\d{1,4})?)|(0\\d{3}-\\d{7,8}(-\\d{1,4})?)$/,
    number: /^(0|[1-9][0-9]*)$/,
    digit: /^(-?\d+)(\.\d+)?$/
}

module.exports = {
    config,
    regExps,
}
