import axios from "axios";
import {config} from "./util";

export default class Tools {
    static copy(content) {
        // content为要复制的内容
        // 创建元素用于复制
        const ele = document.createElement('input')
        // 设置元素内容
        ele.setAttribute('value', content)
        // 将元素插入页面进行调用
        document.body.appendChild(ele)
        // 复制内容
        ele.select()
        // 将内容复制到剪贴板
        document.execCommand('copy')
        // 删除创建元素
        document.body.removeChild(ele)
    }

    static handleH5OpenBtnClick() {
        Tools.copy(window.location.href)
        setTimeout(() => {
            // 如果没有安装app，没有跳转
            if (document.visibilityState === "visible") {
                if (Tools.isIOS()) {
                    window.location.href = config.appStoreUrl
                } else {
                    window.location.href = "https://d.lighttcm.com/#/"
                }

                // 如果打开失败
                setTimeout(() => {
                    if (document.visibilityState === "visible")
                        alert("打开应用市场失败，请您在应用市场内搜索“中济经络穴位”下载或更新哦～")
                }, 1000)
            }
        }, 1000);
    }

    static isIOS() {
        const ua = typeof window === 'object' ? window.navigator.userAgent : '';

        let _isIOS = -1;
        if (_isIOS === -1) {
            _isIOS = /iPhone|iPod|iPad/i.test(ua) ? 1 : 0;
        }
        return _isIOS === 1;
    }

    static isAndroid() {
        let _isAndroid = -1;
        const ua = typeof window === 'object' ? window.navigator.userAgent : '';
        if (_isAndroid === -1) {
            _isAndroid = /Android/i.test(ua) ? 1 : 0;
        }
        return _isAndroid === 1;
    }

    static isWechat() {
        const ua = typeof window === 'object' ? window.navigator.userAgent : '';
        return !!ua.match(/MicroMessenger/i)
    }

    static downloadByUrl(url, filename) {
        return new Promise(((resolve, reject) => {
            axios.get(url, {responseType: 'arraybuffer'}).then(res => {
                Tools.downloadByBlob(res.data, filename)
                resolve(res);
            }).catch((err) => {
                reject(err);
            })
        }));
    }

    static downloadByBlob(blobData, filename) {
        let downloadLink = document.createElement('a');
        downloadLink.download = filename;
        downloadLink.style.display = 'none';
        let blob = new Blob([blobData]);
        downloadLink.href = URL.createObjectURL(blob);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    static getHashQueryString(url, name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let query = (url || window.location.hash).split("?")[1];
        if (!query) return null;
        let r = query.match(reg);
        if (r != null) return decodeURIComponent(r[2]);
        return null;
    }

    static getQueryString(name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURIComponent(r[2]);
        return null;
    }

    static convertToBase64(file, size = undefined) {
        let allowImageFileSize = size || 2 * 1024 * 1024;
        let reader = new FileReader();
        if (file) {
            return new Promise((resolve, reject) => {
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    if (allowImageFileSize !== 0 && reader.result.length > allowImageFileSize) {
                        let MBSize = Math.floor(allowImageFileSize / 1024 / 1024);
                        reject({allow: MBSize + 'MB', obj: e});
                    } else {
                        resolve(reader.result);
                    }
                }
            })
        }
    }

    /**
     * 删除对象中空属性
     * @param obj 对象
     * @returns {*} 结果
     */
    static deleteEmptyOption(obj) {
        if (!(obj instanceof Object)) {
            return;
        }
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && (obj[key] === null || obj[key] === '' || obj[key] === undefined))
                delete obj[key]
        }
        return obj;
    }
}