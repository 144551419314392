import React, {useState} from "react"
import {Form, Input, Button, Radio, message, Spin} from 'antd';
import "./feedback.css";
import {baseServices} from "../../utils/services";
import Tools from "../../utils/tools";

const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 20},
};
const tailLayout = {wrapperCol: {span: 24},};

function Feedback() {
    const [formRef] = Form.useForm();
    const [loading, setLoading] = useState(false);

    function onFinish(values) {
        setLoading(true);
        const user = window.user || {};
        let data = {
            content: JSON.stringify({category: values.category, content: values.content}),
            concat: JSON.stringify({device_type: values.device_type, way: values.way, way_type: values.way_type}),
            appInfo: Tools.getQueryString("appInfo"),
        };
        let path = '/common/feedback';
        if (user.token && user.token !== 'null')
            path += `?token=${user.token}`;

        baseServices.post(path, data).then(res => {
            // 提交处理成功回调
            message.success(res.data.message || "提交成功！");
            formRef.resetFields();
        }).finally(() => {
            setLoading(false);
        })
    }

    function onFinishFailed({errorFields}) {
        formRef.scrollToField(errorFields[0].name);
    }

    return (
        <div className="feedback">
            <Spin spinning={loading}>
                <Form {...layout} labelAlign="left"
                      form={formRef}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      scrollToFirstError>
                    <h3>1、请选择您要反馈的问题类型</h3>
                    <Form.Item {...tailLayout} name="category" initialValue={0} rules={[{required: true}]}>
                        <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value={0}>功能异常</Radio.Button>
                            <Radio.Button value={1}>产品体验</Radio.Button>
                            <Radio.Button value={2}>优化建议</Radio.Button>
                            <Radio.Button value={3}>其他</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item {...tailLayout} name="content" rules={[{required: true, message: "请填写问题描述"}]}>
                        <Input.TextArea showCount maxLength={500} autoSize={{minRows: 3, maxRows: 6}}
                                        placeholder="请描述您的问题，以便我我们尽快为您解决（必填）"/>
                    </Form.Item>
                    <h3>2、请输入联系信息</h3>
                    <Form.Item {...tailLayout} name="device_type">
                        <Input placeholder="请输入手机型号，以便我们尽快定位问题并解决" maxLength={30}/>
                    </Form.Item>
                    <h3>3、联系类型</h3>
                    <Form.Item {...tailLayout} name="way_type">
                        <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value={'QQ'}>QQ</Radio.Button>
                            <Radio.Button value={'Email'}>Email</Radio.Button>
                            <Radio.Button value={'phone'}>电话</Radio.Button>
                            <Radio.Button value={'other'}>其他</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <h3>4、联系方式</h3>
                    <Form.Item {...tailLayout} name="way">
                        <Input maxLength={11} placeholder="请输入联系方式"/>
                    </Form.Item>
                    <p className="tip">我们将妥善保管联系人信息，确保数据安全性</p>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" className="feedback-btn">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

export default Feedback;
