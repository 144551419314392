import React from "react";
import "./index.css";

export default ({title}) => {
    return (
        <div className="medicine-item">
            <div>
                <img src={`/medicines/${title}.png`} draggable="false"/>
                <div className="medicine-detail"><a href={`/medicine/detail/${title}`}><span>查看详情</span></a></div>
            </div>
            <p>{title}</p>
        </div>
    )
}