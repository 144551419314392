import TitlePrefix from "../../images/title-prefix.png";
import React from "react";

export default ({title}) => {
    return (
        <div className="introduce-title" style={{margin: "60px 0"}}>
            <img src={TitlePrefix} draggable={false}/>
            <span>{title}</span>
            <img src={TitlePrefix} style={{transform: 'scaleX(-1)'}} draggable={false}/>
        </div>
    )
}