import React, {useState} from "react";
import './Booklist.css'
import {Button, Form, Input, message, Modal} from "antd";
import Logo from "../../images/logo.png";
import {InfoCircleFilled} from "@ant-design/icons";
import useLocalStorageState from "use-local-storage-state";

export default () => {
    const bookList = require(`../../jsons/book_list.json`);
    // 登录相关
    const [loginVisible, setLoginVisible] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);
    const [currentBook, setBook] = useState(undefined);
    const [userId, setUserId] = useLocalStorageState('userId', {defaultValue: 0})
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJkaXNwb3NhYmxlIiwiZGV2aWNlIjoiIiwiZXZpZGVuY2UiOiJFbGl4aXIuQWN1cG9pbnRBdXRoLkJ1c2luZXNzLkF1dGhlbnRpY2F0ZS5EaXNwb3NhYmxlIiwiZXhwIjozNDUyMDUyMjQ4LCJpc3MiOiJhY3Vwb2ludDNkIiwic3ViIjoxfQ.4hblJIbtvGiJ0f4A5nIo7FunH0YCbKJNSXqZfekHqxs"

    const login = ({account, password}) => {
        if (account === 'zhongji' && password === '123456') {
            message.success('登录成功！');
            setUserId(1);
            setLoginVisible(false);
            if (currentBook) {
                window.location.href = `https://api.lighttcm.com/api/read/book/${currentBook.international_code}/read/zcl/${currentBook.source_file_id}?token=${token}`
            }
        } else {
            setLoginFailed(true);
        }
    }

    const handleClick = (book) => {
        if (userId !== 1) {
            setLoginVisible(true)
            setBook(book)
        } else {
            window.location.href = `https://api.lighttcm.com/api/read/book/${book.international_code}/read/zcl/${book.source_file_id}?token=${token}`
        }
    }

    return (
        <div className="book-list" style={{display: "flex"}}>
            {
                bookList.map((i) => {
                    return <BookItem {...i} handleClickItem={() => handleClick(i)}/>
                })
            }
            <Modal destroyOnClose
                   className='login-modal'
                   maskClosable={false}
                   onCancel={() => setLoginVisible(false)}
                   afterClose={() => {
                       setLoginFailed(false)
                       setBook(undefined)
                   }}
                   open={loginVisible}
                   footer={null}>
                <Form
                    onFinish={login}
                    onValuesChange={(_) => loginFailed && setLoginFailed(false)}
                    wrapperCol={{span: 24}}>
                    <img className='logo' src={Logo} alt='' draggable={false}/>
                    <Form.Item name='account' rules={[{required: true, message: '请输入账号'}]}>
                        <Input placeholder='请输入账号'/>
                    </Form.Item>
                    <Form.Item style={{margin: 0}} name='password'
                               rules={[{required: true, message: '请输入密码'}]}>
                        <Input.Password visibilityToggle={false} placeholder='请输入密码'/>
                    </Form.Item>
                    <div className='login-fail-message'>
                        {loginFailed ? (
                            <>
                                <InfoCircleFilled style={{}}/>
                                <span>账号或密码错误，请重新输入</span>
                            </>
                        ) : ''}
                    </div>
                    <Form.Item><Button type='primary' htmlType='submit'>登录</Button></Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

const BookItem = ({title, author, cover_url, handleClickItem}) => {

    return (
        <div className="book-item" onClick={handleClickItem}>
            <div className="book-img">
                <img src={cover_url} draggable="false"/>
                <div className="book-detail">
                    <span>点击阅读</span>
                </div>
            </div>
            <div className="book-info">
                <h3>{title}</h3>
                <p>{author}</p>
            </div>
        </div>
    )
}