import React, {useState} from "react";
import "./index.css";
import Play from "../../images/play.png";
import Pause from "../../images/pause.png";

export default ({width, height, src, poster}) => {
    const [playing, setPlaying] = useState(false);
    const ref = React.createRef();

    const playOrPause = () => {
        playing ? ref.current.pause() : ref.current.play();
        setPlaying(!playing);
    }

    return (
        <div className="simple-video" onClick={playOrPause} style={{width: `${width}px`, height: `${height}px`}}>
            <video src={src} ref={ref} poster={poster} loop />
            <img className={playing ? 'pause-button' : ''} src={playing ? Pause : Play} draggable="false" />
        </div>
    )
}
