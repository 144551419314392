import {useParams} from "react-router-dom";
import "./MedicineDetail.css";
import {useEffect, useState} from "react";
import {InfoCircleFilled, ReloadOutlined, WechatOutlined} from '@ant-design/icons';
import {Socket} from "phoenix"
import configFunc from "../../../config";
import useLocalStorageState from 'use-local-storage-state'
import QRCode from "react-qr-code";
import {Popover, Spin, Button, Modal, Form, Input, message} from "antd";
import Logo from "../../../images/logo.png";

export default () => {
    const params = useParams();
    const [purchased, setPurchased] = useLocalStorageState(`/medicine/detail/${params.name}/purchased`, {
        defaultValue: false
    });
    const medicine = require(`../../../jsons/medicines/${params.name}.json`);
    // 登录相关
    const [loginVisible, setLoginVisible] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);
    const [userId, setUserId] = useLocalStorageState('userId', {defaultValue: 0})

    const [channel, setChannel] = useState(null);
    const [purchasePopoverOpen, setPurchasePopoverOpen] = useState(false)
    const [wechatPurchaseQRCode, setWechatPurchaseQRCode] = useState(null);

    useEffect(() => {
        let _socket = new Socket(configFunc.SocketEndpoint("/web-socket"), {params: {}})
        _socket.connect()

        let _channel = _socket.channel("portal", {})
        _channel.join()
        setChannel(_channel)

        _channel.on("purchased", (data) => {
            setPurchased(true);
            setWechatPurchaseQRCode(null);
            setPurchasePopoverOpen(false);
        })

        return () => {
            _channel.leave()
            _socket.disconnect()
        }
    }, [])

    const descriptionStage = (title, content) => {
        return (
            <p key={title} className="description-item">
                <b>【{title}】</b>
                <span>{content}</span>
            </p>
        )
    }

    const handleLoadQrCode = () => {
        setWechatPurchaseQRCode(null)
        channel.push("weixin-request-pay-qrcode", {"pricing-id": 40001})
            .receive("ok", ({code_url}) => {
                setWechatPurchaseQRCode(code_url)
            })
    }

    const handlePurchaseClick = (isOpen) => {
        setPurchasePopoverOpen(isOpen)
        handleLoadQrCode()
    }

    const login = ({account, password}) => {
        if (account === 'zhongji' && password === '123456') {
            message.success('登录成功！');
            setUserId(1);
            setLoginVisible(false);
        } else {
            setLoginFailed(true);
        }
    }

    return (
        <div className="medicine-detail-page">
            <div className="medicine-detail">
                <div className="breadcrumb">
                    <a href="/medicines">药品中心</a>
                    <span style={{margin: "0 8px"}}>{">"}</span>
                    <span>药品详情</span>
                </div>
                <div className={`content ${!purchased && userId === 0 ? "locked-content" : ""}`}>
                    <div className="main-description">
                        <div className="medicine-name">
                            <h1>{medicine.name}</h1>
                            {medicine.apellation.map((e) => <p key={e}>{e}</p>)}
                        </div>
                        <img src={`/medicines/${params.name}.png`} className="medicine-pic" draggable="false"/>
                    </div>
                    <div className="sub-description">
                        {medicine.detail.map(e => descriptionStage(e.title, e.content))}
                    </div>
                    <div className={`hide-box ${!purchased && userId === 0 ? "" : "hide-box-locked"}`}>
                        <Popover trigger="click" open={purchasePopoverOpen} onOpenChange={handlePurchaseClick}
                                 content={<div style={{width: 256}}>
                                     {!!wechatPurchaseQRCode ? <>
                                         <div>
                                             <QRCode value={wechatPurchaseQRCode}/>
                                         </div>
                                         <div>
                                             <Button type="primary" block icon={<ReloadOutlined/>}
                                                     onClick={handleLoadQrCode}>刷新</Button>
                                         </div>
                                     </> : <div style={{
                                         width: 256,
                                         height: 256,
                                         display: "flex",
                                         justifyContent: "center",
                                         alignItems: "center"
                                     }}>
                                         <Spin tip="加载中..." size="large"></Spin>
                                     </div>}
                                 </div>}>
                            <div className="scan">
                                <WechatOutlined style={{fontSize: "17px", color: "green"}}/><span>微信扫码支付</span>
                            </div>
                        </Popover>
                        <p className="pay">支付<span><span style={{fontSize: "17px"}}>￥</span>1</span>查看更多详情</p>
                        <Button onClick={() => setLoginVisible(true)} className="login-button"
                                type="primary">登录免费查看</Button>
                        <Modal destroyOnClose
                               className='login-modal'
                               maskClosable
                               onCancel={() => setLoginVisible(false)}
                               afterClose={() => setLoginFailed(false)}
                               open={loginVisible}
                               footer={null}>
                            <Form
                                onFinish={login}
                                onValuesChange={(_) => loginFailed && setLoginFailed(false)}
                                wrapperCol={{span: 24}}>
                                <img className='logo' src={Logo} alt='' draggable={false}/>
                                <Form.Item name='account' rules={[{required: true, message: '请输入账号'}]}>
                                    <Input placeholder='请输入账号'/>
                                </Form.Item>
                                <Form.Item style={{margin: 0}} name='password'
                                           rules={[{required: true, message: '请输入密码'}]}>
                                    <Input.Password visibilityToggle={false} placeholder='请输入密码'/>
                                </Form.Item>
                                <div className='login-fail-message'>
                                    {loginFailed ? (
                                        <>
                                            <InfoCircleFilled style={{}}/>
                                            <span>账号或密码错误，请重新输入</span>
                                        </>
                                    ) : ''}
                                </div>
                                <Form.Item><Button type='primary' htmlType='submit'>登录</Button></Form.Item>
                            </Form>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    )
}